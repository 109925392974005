.App {
  text-align: center;
}
body {
  background: linear-gradient(-45deg, #ffffff, #ffffff, #d4f5ff, #ffffff);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #000000;
}

.App-link {
  color: #61dafb;
}
.Dropdown-root{
  width: 20vw;
  max-width: 20%;
}
.control{
  padding: 8px !important;
}
.col-5{
  background-color: rgba(256,256,256,0.1);
}
